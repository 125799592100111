.landw{
    width: 100vw;
}

.nrw {
    width: 95vw;
}

.messageGYG {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 18px;
    color: #FF5533;
}

.fs16 {
    font-size: 16px !important;
}

.mt2 {
    margin-top: 2rem;
}


@media screen and (min-width: 900px) {
    .nrw {
        width: 50vw;
    }
}
