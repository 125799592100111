.cardContainer{
   width: 85vw !important;
   margin-bottom: 1rem;
}

.singleCard{
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.15)!important;
    border-radius: 10px !important;
}

.noBck {
    border: none !important
}

.bodyCard {
    text-align: left !important;
}

.footerC {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.offerBtn {
    background: linear-gradient(273deg, #ff9a44 0%, #fe534c 100%);
    border: none !important;
}

.smallLabel {
    font-size: 14px;
    color: grey;
}

.fromCityCodeLabel {
    margin-left: 0.15rem;
    font-size: 14px;
}

.accordion-button {
    background-color: white !important;
    color: black !important;
}


.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: black !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    color: black !important;
}

.hr {
    margin: -15px !important;
}

.inRow {
    display: flex;
    align-items: center;
}

.dateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.imgw {
    width: 22px !important;
}

.fs-12 {
    font-size: 12px;
}
