input[type=text]:focus{
    border: 2px solid;
    border-image: linear-gradient(45deg, #ff9a44 , #fe534c) 1;
    outline: none;
    border-radius: 0.375rem !important;
}

.search {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 2px solid;
    border-image: linear-gradient(45deg, #ff9a44 , #fe534c) 1;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.one_city {
    padding: 0.75rem 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.name {
    text-transform: capitalize;
    font-size: 16px;
}

.codeCity {
    background: #fe534c;
    padding: 0.35rem;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    width: 50px;
    text-align: center;
}

.codeState {
    background: #ff9a44;
    padding: 0.35rem;
    color: white;
    border-radius: 10px;
    font-size: 12px;
    width: 50px;
    text-align: center;
}

.input_container {
    position: sticky;
    top: 0px;
}