.react-modal-sheet-container {
    padding: 1rem !important;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    padding-bottom: 1rem;
    position: sticky;
    top: -1px;
    z-index: 9;
    background: white;
}

.f-container {
    position: sticky;
    bottom: -2px;
    z-index: 9;
    background: white;
}

.footerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    /* text-align: center;
    padding-bottom: 1rem;
    position: sticky;
    bottom: -2px;
    z-index: 9;
    background: white; */
}

.imgsize {
    width: 95% !important;
    max-width: 55vw;
}

.headerTxt {
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase;
}

.priceTxt {
    font-weight: 500;
    font-size: 28px;
    text-transform: uppercase;
}

.divider {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 0.15px solid grey;
    width: 80%;
    margin: 0 auto;
}

.flx {
    display: flex;
    align-items: center;
}

.flx-b {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.iconw {
    width: 30px;
}

.smallLabel {
    font-size: 14px;
    color: grey;
}

.smallLabel12{
    font-size: 12px;
    color: grey;
}

.offerBtn {
    background: linear-gradient(273deg, #ff9a44 0%, #fe534c 100%);
    border: none !important;
}

.react-modal-sheet-content::-webkit-scrollbar {
    display: none !important;
}

.react-modal-sheet-content {
    overflow: scroll !important;
}

.iconCorrect {
    width: 20px;
}

.imgw18 {
    width: 18px;
}

.imgw22 {
    width: 22px;
}

.close{
    position: absolute;
    top: 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 91% !important;
}


.scalo {
    text-align: center;
    font-size: 10px;
    color: darkgrey;
}