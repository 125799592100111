.rdrEndEdge {
    color: #FF5533 !important;
}

.rdrStartEdge {
    color: #FF5533 !important;
}

.rdrDayEndPreview {
    color: #FF5533 !important;
}

.rdrDayNumber {
    color: #FF5533 !important;
}

.rdrDayNumber {
    color: #f97459 !important;
}

.rdrInRange {
    color: #f97459 !important;
}

.rdrDayToday .rdrDayNumber span:after {
    background: #FF5533 !important;
}

.rdrDateDisplayItemActive {
    border-color: #FF5533 !important;
}

.choosen_btn_container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0.5rem;
}

.choose_button {
    background: #FF5533 !important;
    color: white !important;
    width: 30%;
    border: none !important;
}

.center {
    display: flex;
    justify-content: center;
}