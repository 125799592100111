.shareContainer {
    cursor: pointer !important;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.socialIcon {
    width: 10vw;
    min-width: 45px;
    max-width: 45px;
}