.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer {
    cursor: pointer !important;
}

.round_one {
    border: 1px solid white;
    border-radius: 50px;
    white-space: nowrap;
}

.w-65 {
    width: 65% !important;
}

.r-o-check{
    width: 50%;
    text-align: center;
    color: #0d1417 !important;
    background: white;
    border-radius: 50px;
    font-weight: 700;
    font-size: 10px;
}

.r-o-unCheck {
    width: 50%;
    text-align: center;
    color: white;
    font-size: 10px;
    font-weight: 700;
}

.f_sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.code {
    font-size: 65px;
    font-weight: 900;
    color: white;
    max-height: 10vh;
}

.city {
    font-size: 12px;
    text-align: center;
    /* letter-spacing: 2px; */
    text-transform: uppercase;
    font-weight: 800;
    width: 80%;
    border-radius: 50px;
    padding-bottom: 2.25%;
    color: white;
    max-width: 50vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.round_trip {
    width: 24px;
    height: 24px;
    z-index: 9;
}

.price_container {
    width: 70%;
    margin: 0 auto;
}

.price_txt {
    color: white;
    font-size: 14px;
}

.rangeslider{
    margin-top: 1% !important;
}

.rangeslider-horizontal{
    height: 6px !important;
}

.rangeslider, .rangeslider .rangeslider__fill{
    box-shadow: none
}

.rangeslider-horizontal .rangeslider__handle{
    width: 20px !important;
    height: 20px !important;
}

.rangeslider-horizontal .rangeslider__fill{
    background: linear-gradient(273deg, #ff9a44 0%, #fe534c 100%) !important;
}

.rangeslider-horizontal .rangeslider__handle:after{
    content: none !important;
}

.year_mess {
    font-size: 12px;
    color: whitesmoke;
}

.css-b62m3t-container {
    width: 100% !important;
}

.css-t3ipsp-control{
    border-color: #ffffff !important;
    box-shadow: none !important;
}

.css-lm8j94-menu{
    color: black !important;
}

.css-1fdsijx-ValueContainer{
    padding: 5px 8px !important;
}

.find_btn {
    color: #0d1417 !important;
    font-weight: 600 !important;
}

.rdrDateDisplayItem input {
    color: #0d1417 !important;
}

.dropdown-toggle {
    opacity: 0 !important;
}

.form-control:focus{
    border-color: #FF5533 !important;
    box-shadow: 0 0 0 0.1rem #FF5533 !important;
}

.form-check:focus {
    border-color: #FF5533 !important;
    box-shadow: 0 0 0 0.1rem #FF5533 !important;
}

.spanAsInput {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: black !important;
    cursor: pointer !important;
    height: 2.5em !important;
    line-height: 2.5em !important;
}

.lh2 {
    line-height: 2.15rem !important;
}