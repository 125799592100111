@import url('https://fonts.cdnfonts.com/css/lemonmilk');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #e6e5e5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
}

.bck-w {
  background-color: white !important;
}

.relative {
  position: relative;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 0.15px solid grey;
  width: 80%;
  margin: 0 auto;
}

.mt2 {
  margin-top: 2rem !important;
}

.messageGYG {
  font-family: 'Lemon/Milk', sans-serif !important;
  font-size: 16px;
  color: #FF5533;
}

.spinner {
  z-index: 99999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 50% !important;
  bottom: 0 !important;
}

.center_f {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centralize {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.toCityTxt {
  position: absolute;
  color: white;
  text-transform: uppercase;
  font-family: 'Lemon/Milk', sans-serif !important;
  font-size: 32px;
  letter-spacing: 2.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80vw;
}

.shareContainer {
  cursor: pointer !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.socialIcon {
  width: 10vw;
  min-width: 45px;
  max-width: 45px;
}

.mt2 {
  margin-top: 2rem;
}

.socialIconMin {
    max-width: 36px;
    min-width: 36px;
    width: 36px;
}

.iconContainer {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
}

.centerize {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.toast-container {
  top: 90vh !important;
}

.toast-header{
  background: linear-gradient(273deg, #ff9a44 0%, #fe534c 100%) !important;
  color: white !important;
}

.white{
  color: white !important;
}

.toast {
  background: transparent !important;
}

.w-45 {
  width: 45% !important;
}

.w-60 {
  width: 60% !important;
}

.w-85 {
  width: 85vw !important;
}

.w-90 {
  width: 90vw !important;
}

.w-95 {
  width: 95% !important;
}

.w-33 {
  width: 33% !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.noShadow {
  box-shadow: none !important;
}

.booking-btn-offers {
  background: white;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  border-radius: 40px;
}

.blck {
  color: black !important;
}