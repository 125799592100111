.guideDesc{
    max-height: 10vh;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    text-align: left;
    letter-spacing: -1px;
    color: darkslategrey;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.cardGuideTitle {
    text-transform: uppercase;
}

.viewsTxt {
    color: grey;
    font-size: 9px;
}