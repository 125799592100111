.premi_container {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 90vw;
}

.txt_premi {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 12px;
    color: grey;
    padding: 0 0.5rem;
}

.w25{
    width: 25vw  !important;
}

.w-90 {
    width: 90vw;
}

.footer_container {
    position: absolute;
    bottom: 0px;
    width: 100vw;
    background: white;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    /* padding-bottom: 2rem; */
    padding-top: 1rem;
}

.footer_container_rules {
    position: absolute;
    bottom: 0px;
    width: 90vw;
    background: white;
}

.play_btn {
    background: #e8505b !important;
    color: white !important;
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 20px !important;
}

.pt_txt {
    position: absolute;
    top: 45%;
    width: 100%;
}

.user_info_txt {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 14px;
    color: white !important;
}

.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.countDown {
    font-family: 'Lemon/Milk', sans-serif !important;
    color: dimgrey !important;
    font-size: 24px;
}

.info {
    font-family: 'Lemon/Milk', sans-serif !important;
    color: dimgrey !important;
    font-size: 9px;
}

.retireAward {
    white-space: nowrap;
    width: 30vw;
    border: 3px solid white;
    border-radius: 25px;
    padding: 0.25rem;
}

.close_mess {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 12px;
    color: black !important;
}

.sheetBck {
    width: 100vw;
    height: 85vh;
    overflow: hidden;
    margin-top: 1rem;
}

.question {
    position: absolute;
    top: 10vh;
    margin: 0 auto;
    color: white;
    font-family: Lemon\/Milk,sans-serif!important;
    font-size: 22px;
    padding: 0 1rem;
}

.form-check-input:checked{
    background-color: #fae107  !important;
    border-color: #fae107  !important;
}

.form-check-label {
    font-size: 18px !important;
    white-space: nowrap;
}

.confirm_btn {
    position: absolute;
    top: 55vh;
    width: 95%;
}

.confirm{
    color: #f53 !important;
}

.gameCountdown {
    position: absolute;
    top: 1vh;
    margin: 0 auto;
    color: white;
    font-family: Lemon\/Milk,sans-serif!important;
}

.login-with-google-btn {
    transition: background-color .3s, box-shadow .3s;
      
    padding: 12px 16px 12px 42px;
    border: none;
    border-radius: 3px;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    
    color: #757575;
    font-size: 14px;
    font-weight: 500;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    background-color: white;
    background-repeat: no-repeat;
    background-position: 12px 11px;
    
    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
    }
    
    &:active {
      background-color: #eeeeee;
    }
    
    &:focus {
      outline: none;
      box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
    }
    
    &:disabled {
      filter: grayscale(100%);
      background-color: #ebebeb;
      box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
      cursor: not-allowed;
    }
  }