.guideTitle {
    font-size: 20px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98vw;
    position: absolute;
    top: 15%;
    width: 100vw;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-family: 'Lemon/Milk', sans-serif !important;
}

.city_name {
    font-size: 45px !important;
}

.bck_img {
    max-width: 100vw;
}