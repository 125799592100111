.shadow {
    box-shadow: 0 2px 8px 0 rgb(35 35 35 / 16%) !important;
}

.findF {
    font-size: 10px !important;
    padding: 5px;
    margin-right: 3px;
    border: 1px solid #FF5533;
    border-radius: 50px;
}

.r_desc {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}

.direct {
    font-size: 9px;
    color: darkslategray;
    white-space: nowrap;
}

.redirect {
    position: sticky;
    bottom: 0 !important;
    background: white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}