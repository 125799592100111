.landing_title{
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 36px;
    color: #e8505b !important;
}

.landing_txt {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 12px;
    color: #e8505b !important;
}

.spinnerLink {
    z-index: 99999 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 auto !important;
    margin-top: 2rem !important;
}

.landing_btn {
    background: #e8505b !important;
    color: white !important;
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 12px !important;
}