.acc {
    position: sticky;
    top: 1vh;
    z-index: 9999;
    width: 97%;
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.15)!important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.acc_bck{
    background: transparent !important;
    border: none !important;
}

.accordion-item{
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.acc_body {
background-image: url('../../assets/accBck.jpg') !important;
    background-size: cover;
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.icon_menu{
    position: sticky;
    top: 95vh;
    z-index: 99;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
}

.icon_btn{
    border-radius: 100px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.booking-btn {
    background: white;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
    border-radius: 40px;
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.15)!important;
}

.guideImg {
    position: absolute;
    right: 5vw;
}