.cardContainer{
    width: 85vw !important;
    margin-bottom: 1rem;
}

.singleC{
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.15)!important;
    border-radius: 10px !important;
}

.bodyC {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left !important;
}


.offerBtn {
    background: linear-gradient(273deg, #ff9a44 0%, #fe534c 100%);
    border: none !important;
}

.cnt{
    display: flex;
    align-items: center;
}


@media screen and (min-width: 900px) {
    .cardContainer{
        width: 55vw !important;
    }
}