.w85 {
    width: 85vw !important;
}

.boldTxt {
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
}

.flx-sb {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.d-col {
    flex-direction: column;
}