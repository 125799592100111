.linksTitleContainer {
    font-size: 20px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 99vw;
    position: absolute;
    top: 10%;
    width: 100vw;
    text-align: center;
    color: white;
    text-transform: uppercase;
    font-family: 'Lemon/Milk', sans-serif !important;
}

.fh {
    font-size: 40px !important;
}

.subtitle {
    font-size: 15px !important;
}

.linkTitle {
    font-family: Lemon\/Milk,sans-serif!important;
    font-size: 16px !important;
    color: #f53!important;
}

.linkMessage {
    font-family: Lemon\/Milk,sans-serif!important;
    color: grey;
    margin-bottom: 0.5rem;
}

.linkMenu {
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    padding: 1rem 1.5rem;
    position: sticky;
    bottom: -2px;
    z-index: 9;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}