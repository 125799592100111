.homePhoto {
    width: 100vw;
}

.offerTxt {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 18px;
    color: #FF5533 !important;
}

.shareTxt {
    font-family: 'Lemon/Milk', sans-serif !important;
    font-size: 14px;
    color: #FF5533;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mt2 {
    margin-top: 2rem;
}

.shadowP {
    box-shadow: 0 7px 7px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.15)!important;
}

.container_form {
    position: absolute;
    top: 55vh;
    max-width: 100vw;
    width: 100vw;
}

.mobile_bck {
    height: 96vh !important;
    max-height: 96vh !important;
    filter: blur(0.5px);
}

.lastO_c {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal_footer {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.footer_btn {
    min-width: 100px;
}

.pwa_checkbox {
    font-size: 10px !important;
}

.form-check {
    margin: 0 !important;
}

.form-check-input:checked{
    background-color: #FF5533 !important;
    border-color: #FF5533 !important;
}

.absolute {
    position: absolute !important;
}

.desk_bck {
    background-color: rgb(0 0 0 / 15%);
    backdrop-filter: blur(5px);
    top: 5vh !important;
    padding: 15px;
    border-radius: 10px;
    width: 60vw !important;
}

.apple_modal {
    position: absolute !important;
    bottom: 0 !important;
}

.modal_footer_iphone {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.iphone_footer {
    text-align: center;
    color: #f53;
    font-family: 'lemon/milk';
    font-size: 12px;
}