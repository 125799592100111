.linkimg {
    width: 18px;
    height: 18px;
    opacity: 80%;
}

.attimg {
    max-width: 80vw !important;
    border-radius: 10px;
}

.att_description {
    font-size: 12px;
    color: darkslategray;
}

.a_body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: justify !important;
}

.att_title {
    font-size: 16px !important;
}

.imgw16 {
    width: 16px;
}